/* eslint-disable no-irregular-whitespace */
import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useState } from 'react'
import styled from 'styled-components'
import arrow from '../../assets/images/curly-arrow.svg'
import { validateWorkEmailInput } from '../../formUtils'
import { trackDeal } from '../../googleTagManager'
import { useBase64EmailFromUrl } from '../../hooks'
import { sendContactDataToHS } from '../../hubspot'
import GetStartedForm, { ContactData } from './GetStartedForm'

type Props = {
  campaign?: { value: string; audience: string }
  formId: string
}

export function TrialForm({ campaign, formId }: Props) {
  const emailFromUrl = useBase64EmailFromUrl()
  const [email, setEmail] = useState(emailFromUrl || '')

  return (
    <div className="cta-form">
      <GetStartedForm
        formId={formId}
        campaign={campaign}
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
      />
      {!campaign && (
        <FreeReportAd>
          <img src={arrow} alt="arrow" />
          <div>You&#39;ll get your first insights in 10 minutes</div>
        </FreeReportAd>
      )}
    </div>
  )
}

const FreeReportAd = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0px 0px 170px;
  div {
    text-align: left;
    margin-left: 12px;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
    width: 250px;
  }

  // Prevent viewport scroll on smaller screens;
  @media screen and (max-width: 480px) {
    padding-left: 40px;
  }
`

function onSubmit(
  dealData: ContactData,
  inputRefs: { email: HTMLInputElement | null },
  setErrorMsg: (message: string) => void,
  setLoading: (value: boolean) => void,
  formId: string,
  isCampaign: boolean,
  _isSmallTeam: boolean,
) {
  return async e => {
    e.preventDefault()

    // If you find yourself adding more than 1-3 rules to this custom validation logic,
    // please consider using something like react-hook-form to untangle the mess.
    if (!inputRefs.email || !validateWorkEmailInput(inputRefs.email)) {
      return
    }

    setLoading(true)
    try {
      const res = await sendContactDataToHS(dealData, formId)
      trackDeal(isCampaign ? 'campaign' : 'trial', dealData)
      if (res.status === 200) {
        setErrorMsg('')
        await navigateToThankYouPage(dealData)
      } else {
        setErrorMsg('Oops, an error. Please try again.')
        // @ts-ignore
        Sentry.captureException(JSON.stringify(res))
      }
    } catch (error) {
      // @ts-ignore
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }
}

function navigateToThankYouPage(dealData: ContactData) {
  if (!dealData.sourceCodeHosting?.includes('GitHub')) {
    const queryParams = queryString.stringify({
      origin: 'trial',
      target: 'source-code-hosting-not-supported',
      ...(dealData.sourceCodeHosting
        ? { sch: dealData.sourceCodeHosting }
        : {}),
    })
    return navigate(`/thank-you?${queryParams}`)
  }

  const queryParams = queryString.stringify({
    origin: 'trial',
    target: 'install',
  })
  return navigate(`/thank-you?${queryParams}`)
}
